import { createSlice } from '@reduxjs/toolkit';

export const toolsSlice = createSlice({
  name: 'tools',
  initialState: {
    activeTool: null,
    activeLayer: {
      stepId: null,
      layerId: null
    },
    stepId: null,
    activeStep: {},
    learningMaterialId: null,
    historyArr: [],
    historyPosition: -1
  },
  reducers: {
    setActiveTool: (state, action) => {
      state.activeTool = action.payload;
    },
    removeActiveTool: (state, action) => {
      state.activeTool = null;
    },
    setActiveLayer: (state, action) => {
      state.activeTool = null;
      state.activeLayer = action.payload;
    },
    removeActiveLayer: (state, action) => {
      state.activeLayer = null;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setLearningMaterialId: (state, action) => {
      state.learningMaterialId = action.payload;
    },
    addHistory: (state, action) => {
      if (state.historyPosition < state.historyArr.length - 1) {
        state.historyArr.splice(state.historyPosition + 1);
      }
      state.historyArr.push(action.payload);
      state.historyPosition++;
    },
  }
});

export const {
  setActiveTool,
  removeActiveTool,
  setActiveLayer,
  removeActiveLayer,
  setStepId,
  setActiveStep,
  setLearningMaterialId,
  addHistory
} = toolsSlice.actions;

export const selectActiveTool = (state) => state.tools.activeTool;

export const selectActiveLayer = (state) => state.tools.activeLayer;

export const getActiveStep = (state) => {
  return state.tools.activeStep || state.base.steps[0];
};

export const getActiveLayers = (state) => {
  return state.tools.activeStep.layers;
}

export const getLearningMaterialId = (state) => state.tools.learningMaterialId;

export const getHistory = (state) => state.tools.historyArr;

export const getCurrentHistory = (state) => {
  return state.tools.historyArr[state.tools.historyPosition];
};

export const getHistoryPosition = (state) => state.tools.historyPosition;

export default toolsSlice.reducer;
