import React, { useEffect, useState } from 'react';
import { PlusGreyIcon } from '../../assets/Icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  addStep,
  allSteps,
  updateStep,
  deleteStep,
  removeLayerConfig,
  changeStepOrder
} from '../../../redux/stepsSlice';
import {
  setActiveStep,
  getActiveStep,
  removeActiveLayer
} from '../../../redux/toolsSlice';
import EditStepItem from './EditStepItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const StepSidebar = () => {
  const dispatch = useDispatch();
  const steps = useSelector((state) => allSteps(state));
  const activeStep = useSelector((state) => getActiveStep(state));
  const [editingStep, setEditingStep] = useState(null);
  const material_type = window?.location?.pathname?.split('/')?.[2];
  const handleNewStep = () => {
    dispatch(addStep({ material_type }));
  };

  const isActiveStep = (index) => {
    if (activeStep === undefined) return false;
    return activeStep.id === index;
  };

  const handleStepChange = (step) => {
    dispatch(setActiveStep(step));
    dispatch(removeActiveLayer());
    dispatch(removeLayerConfig());
  };

  const handleDoubleClick = (step) => {
    setEditingStep(step.id);
  };

  const handleEditChange = (e) => {
    const updatedStep = {
      ...activeStep,
      [e.target.name]: e.target.value
    };
    dispatch(updateStep(updatedStep));
  };

  const handleCancelEdit = () => {
    setEditingStep(null);
  };

  const handleRemoveStep = (stepId) => {
    dispatch(deleteStep(stepId));

    if (steps.length > 1) {
      const newActiveStep = steps.find((step) => step.id !== stepId);
      dispatch(setActiveStep(newActiveStep));
    }
  };

  const moveStep = (fromIndex, toIndex) => {
    dispatch(changeStepOrder({ fromIndex, toIndex })); 
  }

  const renderSteps = () => {
    if (steps === undefined) return;
    return steps.map((step, index) => {
      const className = `step-block ${isActiveStep(step.id) ? 'active' : ''}`;
      return (
        <DndProvider backend={HTML5Backend}>
          <div
            key={index}
            onClick={() => handleStepChange(step)}
            onDoubleClick={() => handleDoubleClick(step)}
          >
            <EditStepItem
              step={step}
              index={index}
              className={className}
              edit={editingStep === step.id}
              material_type={material_type}
              handleEditChange={handleEditChange}
              handleCancelEdit={handleCancelEdit}
              handleRemoveStep={handleRemoveStep}
              moveStep={moveStep}
            />
          </div>
        </DndProvider>
      );
    });
  };

  useEffect(() => {
    if (steps === undefined) return;
    dispatch(setActiveStep(steps[0]));
  }, []);

  return (
    <div className="un-steps-sidebar">
      <div className="un-header">
        <p>Steps</p>
        <div className="btn-add" onClick={handleNewStep}>
          <PlusGreyIcon />
        </div>
      </div>
      <div className="body">{renderSteps()}</div>
    </div>
  );
};

export default StepSidebar;
