import React from 'react'
import Button from '../../atoms/button/Button';
import { CogIcon } from '../../pages/paths/assets/icons';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = {
  STEP: 'step',
};

const EditStepItem = ({ index, className, material_type, step, edit, handleEditChange, handleCancelEdit, handleRemoveStep, moveStep }) => {
  const [{isDragging}, drag] = useDrag({
    type: ItemTypes.STEP,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.STEP,
    drop: (item) => {
      moveStep(item.index, index);
    },
  });

  return (
    <div className={`${className} ${isDragging ? 'dragging' : ''}`} ref={edit ? drag : drop}>
      {edit ? (
        <>
          <button type="button" className="move-icon" ref={drag}>
            <CogIcon />
          </button>
          <input
            type="text"
            name="name"
            value={step.name}
            onChange={handleEditChange}
            className="step-name"
          />
          <input
            type="text"
            name="title"
            value={step.title}
            onChange={handleEditChange}
            className="step-title"
          />
          {material_type !== "paths" &&
            <textarea
              type="text"
              name="description"
              value={step.description}
              onChange={handleEditChange}
              className="step-description"
            />
          }
          <div className="un-btn-group">
            <Button className={'ghost'} onClick={handleCancelEdit}>
              Save
            </Button>
            <Button
              className={'ghost red'}
              onClick={() => handleRemoveStep(step.id)}
            >
              Delete
            </Button>
          </div>
        </>

      ) : (
        <>
          <p className="step-name">{step.name}</p>
          <p className="step-title">{step.title}</p>
          {material_type !== "paths" &&
            <p className="step-description">{step.description}</p>
          }
        </>
      )}
    </div>
  )
}

export default EditStepItem